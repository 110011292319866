header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: $background;
  z-index: 10;

  .burger {
    height: 2rem;
  }
  aside {
    display: none;
  }
  .cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 200vh;
    width: 100vw;
    background: $primary;
    p {
      font-size: 1.25rem;
    }
    .cover-content {
      padding: 0 1.3rem;
      ul {
        font-size: 2.25rem;
        padding-top: 3rem;
        font-weight: 700;
        user-select: none;
        li + li {
          padding-top: 1.5rem;
        }
      }
      @media #{$mq-tablet} {
        padding: 0 1.8rem;
      }
    }
  }
  &.opened {
    padding: 0;
    .cover {
      display: block;
    }
  }
  @media #{$mq-large} {
    .cover {
      display: none !important;
    }
    .mobile-wrapper {
      display: none !important;
    }
    aside {
      display: flex !important;
      color: $white;
      text-align: right;
      padding: 3rem 3rem 3rem 1.875rem;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      h1 {
        font-family: 'NeueMachina';
        font-size: 6rem;
        background-color: $primary;
        margin: 0;
        color: $background;
        padding: 0.2em 0.2em 0rem 0.15em;
      }
      p {
        font-size: 2.125rem;
        margin-top: 1.25rem;
      }
      .separator {
        width: 6.25rem;
        height: 1rem;
        margin: 3rem 0 1.5rem 0;
        background: $green-gradient;
        align-self: end;
      }
      .menu {
        font-weight: 700;
        font-size: 3.75rem;
        padding-bottom: 4.3125rem;
        li+li{
          padding-top: 1rem;
        }
      }
      .leaders {
        font-size: 1.875rem;
        display: none;

        &:before {
          content: attr(ul-title);
        }
        li:first-child{
          margin-top: 1rem;
        }

        //long-narrow screen adaption
        @media (min-height: 55rem) {
            display: block;
        }
      }
      .leaders-compact {
        font-size: 1.875rem;
        max-width: 28rem;
        margin-top: 0.5rem;

        //long-narrow screen adaption
        @media (min-height: 55rem) {
          display: none;
        }
      }
    }
  }
}
