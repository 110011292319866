@font-face {
  font-family: 'NeueMontreal';
  src: url('./NeueMontreal/NeueMontreal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMontreal';
  src: url('./NeueMontreal/NeueMontreal-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'NeueMontreal';
  src: url('./NeueMontreal/NeueMontreal-Medium.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMontreal';
  src: url('./NeueMontreal/NeueMontreal-MediumItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'NeueMachina';
  src: url('./NeueMachina/NeueMachina-Ultrabold.woff2') format('woff2'),
       url('./NeueMachina/NeueMachina-Ultrabold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'IBMPlexMono';
  src: url('./IBMPlexMono/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
