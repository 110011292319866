.program-card {
  width: 100%;
  background-color: $green-dark;
  border-radius: 0.5rem;
  border: 1px solid $green-light;
  padding: 1rem 1rem 1rem;
  margin-bottom: 1rem;

  @media #{$mq-tablet} {
    padding: 1rem 1rem 2rem;
  }

  .time {
    color: $light-gray;
    line-height: 1.25rem;
    font-size: 0.875rem;
  }

  .talk-title {
    line-height: 1.625rem;
    font-size: 1.125rem;
    border-bottom: 1px solid $green-light;
    margin: 0.75rem 0 1.25rem;
    padding-bottom: 0.75rem;
  }

  .talk-speaker {
    display: flex;
    flex-direction: column;

    @media #{$mq-tablet} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .speaker-info {
    line-height: 1.5rem;
    font-size: 1rem;
    font-style: italic;
  }

  .topic {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      line-height: 1.25rem;
      font-size: 0.875rem;
    }

    .box {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 0.125rem;
      margin-right: 0.25rem;
    }
  }
}