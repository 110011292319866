.program-layout {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  font-family: 'NeueMontreal';
  color: $white;
  margin: 5rem 1rem;

  h2 {
    display: flex;
    justify-content: space-between;

    line-height: 2.5rem;
    font-size: 2.125rem;
    margin: 0;

    @media #{$mq-tablet} {
      margin: 0 0 1.5rem;
    }
  }

  .filter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;

    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: $green;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }

    @media #{$mq-tablet} {
      display: none;
    }
  }

  .active-filter-btn {
    background-color: $green-dark;
  }

  @media #{$mq-tablet} {
    width: 43rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media #{$mq-large} {
    h2 {
      line-height: 4.5rem;
      font-size: 3.75rem;
    }
  }
}

.program-container {
  display: flex;
  flex-direction: column;

  @media #{$mq-tablet} {
    flex-direction: row;
  }

  .program-sidebar {
    margin-bottom: 1rem;

    .program-sidebar-container {
      top: 5rem;

      @media #{$mq-large} {
        top: 1rem;
      }

      position: sticky;
    }

    @media #{$mq-tablet} {
      margin-right: 2rem;
    }
  }
}

.day-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media #{$mq-tablet} {
    flex-direction: column;
  }
}
