.speakers-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  font-family: 'NeueMontreal';
  color: $white;
  margin: 5rem 1rem;

  h2 {
    line-height: 4.5rem;
    font-size: 3.75rem;
    margin: 0 0 1.5rem;
  }

  h3 {
    margin: 0 0 1.5rem;
  }

  @media #{$mq-tablet} {
    width: 43rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media #{$mq-large} {
    h2 {
      line-height: 4.5rem;
      font-size: 3.75rem;
    }

    h3 {
      line-height: 1.625rem;
      font-size: 1.125rem;
    }
  }
}

.speakers-list {
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 10rem);
  justify-content: center;
}
