.horizontal-line {
  width: 100%;
  background: $green-gradient;
  border: none;
  margin: 0;
  height: 1.5rem;

  @media #{$mq-tablet} {
    height: 2.4rem;
  }

  @media #{$mq-large} {
    height: 4.4rem;
  }
}
