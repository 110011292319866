.mobile-wrapper {
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    background: $primary;
    font-family: 'NeueMachina';
    font-size: 2.25rem;
    padding: 0.2em 0.2em 0rem 0.15em;
    margin: 0;
    user-select: none;
  }
  &.opened {
    .burger svg line {
      stroke: $background;
    }
  }
  @media #{$mq-tablet} {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }
}
