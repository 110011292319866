.day-card-day {
  color: $light-gray;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.day-card-navigation {
  margin-bottom: 1rem;
  margin-right: 2rem;

  .active {
    color: $green !important;
  }

  a {
    text-decoration: underline;
    color: $white;

    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -2%;
    padding-bottom: 0.5rem;
    padding-top: 0.25rem;


    &:link {
      color: $white;
    }

    &:visited {
      color: $white;
    }
  }
}
