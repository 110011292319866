.program-filter-container {
  display: none;
  width: 100%;
  background-color: $green-dark;
  border-radius: 0.5rem;
  border: 1px solid $green-light;
  padding: 1rem 1rem 2rem;
  margin-bottom: 1rem;
  margin-top: 0.75rem;

  @media #{$mq-tablet} {
    margin-top: 1.5rem;
  }

  .filter-header {
    font-size: 1.125rem;
    line-height: 1.625rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $green-light;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media #{$mq-tablet} {
    width: 12rem;
  }
}

.show-filter {
  display: block;

  @media #{$mq-tablet} {
    display: none;
  }
}

.hide-on-mobile {
  display: none;

  @media #{$mq-tablet} {
    display: block;
  }
}

.program-filter-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .label {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .clear-btn {
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
  }
}

.checkbox-container {
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

input[type="checkbox"] {
  appearance: none;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 0.938rem;
  height: 0.938rem;
  border: 0.125rem solid $green-light;
  border-radius: 0.125rem;

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.55rem;
  height: 0.55rem;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $white;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:checked {
  background-color: $green-light;
}
