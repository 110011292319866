.speaker-card {
  img {
    height: 10rem;
    width: 10rem;

    border-radius: 0.5rem;
  }

  p {
    line-height: 1.5rem;
    font-size: 0.938rem;
    text-align: center;
  }
}
