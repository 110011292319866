.hero-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  max-width: 20rem;
  margin: 8rem auto 10rem;

  @media #{$mq-tablet} {
    max-width: 40rem;
    margin: 5rem auto 6rem;
  }

  @media #{$mq-large} {
    margin-top: 0;
    margin-bottom: 0;
    height: calc(100vh - 8.8rem);
  }
}

.hero-text {
  margin: 1rem 0 0.5rem;
  line-height: 2.3rem;
  font-size: 2rem;

  @media #{$mq-tablet} {
    line-height: 4.5rem;
    font-size: 3.75rem;
  }
}

.hero-sub-text {
  letter-spacing: -2%;
  line-height: 1.75rem;
  font-size: 1.25rem;
  font-weight: 400;

  @media #{$mq-tablet} {
    line-height: 2.5rem;
    font-size: 2.125rem;
  }
}

.details {
  padding: 0.5rem 0;
  line-height: 1.375rem;
  font-size: 1.25rem;

  @media #{$mq-tablet} {
    padding: 1rem 0;
    line-height: 2.5rem;
    font-size: 2.125rem;
  }
}

.hosted-by {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  line-height: 1.375rem;
  font-size: 1.25rem;

  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;

    @media #{$mq-tablet} {
      width: 3.25rem;
      height: 3.25rem;
    }
  }

  a {
    color: $white;

    &:link {
      color: $white;
    }

    &:visited {
      color: $white;
    }
  }

  @media #{$mq-tablet} {
    margin: 1rem 0;
    line-height: 2.5rem;
    font-size: 2.125rem;
  }
}
