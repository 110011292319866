.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.click-effect{
  a {
    text-decoration: none;
    color: inherit;

    &:link {
      color: inherit;
    }

    &:visited {
      color: inherit;
    }
  }
  cursor: pointer;
  &:hover{
    filter: opacity(0.8);
  }
  &:active{
    filter: opacity(0.6);
  }
}