.program-section-day {
  color: $light-gray;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
.program-section-title {
  letter-spacing: -2%;
  line-height: 2rem;
  font-size: 1.5rem;
  margin: 0.25rem 0 1.5rem;

  @media #{$mq-tablet} {
    line-height: 2.5rem;
    font-size: 2.125rem;
  }
}
.program-section-container {
  width: 100%;
}
