.landing {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  main {
    color: $white;
    width: 100%;
    .count-down{
      font-family: 'IBMPlexMono';
      margin: 2rem 1rem 0 1rem;
    }
  }
  @media #{$mq-tablet} {
    main {
      .count-down{
        font-family: 'IBMPlexMono';
        margin: 2rem 1rem 0 2rem;
        font-size: 1.5625rem;
      }
    }
  }
  @media #{$mq-large} {
    min-height: 100vh;
    main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100vw - 33.5rem);
      .count-down{
        font-family: 'IBMPlexMono';
        margin: 0 1rem 0 6vw;
      }
    }
  }
}
