$primary: #FFFF38;

$background: #000000;

$white: #FFFFFF;

$light-gray: #BFBFBF;

$green-gradient: linear-gradient(90deg, #154016 0%, #00CD9F 100%);

$green: #06A77A;

$green-dark: #1B2824;

$green-light: #3C504A;