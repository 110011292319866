@import "./fonts/font-faces.scss";
@import "./colors.scss";
@import "./breakpoints.scss";
@import "../pages/App.scss";
@import "../containers";
@import "../components";
@import "./common.scss";

html {
  box-sizing: border-box;
  height: 100%;
  p{
    margin: 0;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100%;
  font-family: "NeueMontreal", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
  #root {
    height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
